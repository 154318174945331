const ProjectsIcon = () => {
    
    return (
       <>
        <svg xmlns="http://www.w3.org/2000/svg" width="33.3" height="23.61" viewBox="0 0 33.3 23.61">
            <path id="laptop-code-solid" d="M6.46,35.23H25.84V48.15h3.23V34.423A2.431,2.431,0,0,0,26.648,32h-21A2.432,2.432,0,0,0,3.23,34.423V48.15H6.46ZM31.493,49.765H.808A.815.815,0,0,0,0,50.573v.808a3.236,3.236,0,0,0,3.23,3.23H29.07a3.236,3.236,0,0,0,3.23-3.23v-.808A.813.813,0,0,0,31.493,49.765ZM18.467,44.829a1.011,1.011,0,0,0,1.425,0L22.314,42.4a1.011,1.011,0,0,0,0-1.429l-2.423-2.423a1.01,1.01,0,1,0-1.427,1.429l1.709,1.708L18.465,43.4A1.008,1.008,0,0,0,18.467,44.829Zm-4.633-6.273a1.009,1.009,0,0,0-1.427,0L9.984,40.978a1.011,1.011,0,0,0,0,1.429l2.423,2.423a1.018,1.018,0,0,0,.716.292,1.009,1.009,0,0,0,.714-1.724L12.128,41.69l1.709-1.708A1.1,1.1,0,0,0,13.833,38.556Z" transform="translate(0.5 -31.5)" fill="#fff" stroke="#000" strokeWidth="1"/>
        </svg>
       </>
    )

}

export default ProjectsIcon