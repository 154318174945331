import React from 'react'
import {FaThumbsDown, } from 'react-icons/fa';

function Page404() {
  return (
    <div className='error-page'>

      <p>404 - This page does not exit...  exist </p>
      <FaThumbsDown/>

    </div>
  )
}

export default Page404