const ContactIcon= () => {
    
    return (
       <>
      <svg xmlns="http://www.w3.org/2000/svg" width="28.208" height="21.406" viewBox="0 0 28.208 21.406">
  <path id="envelope-solid" d="M24.657,64a2.551,2.551,0,0,1,1.53,4.591L14.624,77.264a1.706,1.706,0,0,1-2.041,0L1.02,68.591A2.551,2.551,0,0,1,2.551,64ZM11.563,78.624a3.4,3.4,0,0,0,4.081,0l11.563-8.673V81a3.4,3.4,0,0,1-3.4,3.4H3.4A3.4,3.4,0,0,1,0,81V69.952Z" transform="translate(0.5 -63.5)" fill="#fff" stroke="#000" strokeWidth="1"/>
</svg>
       </>
    )

}

export default ContactIcon